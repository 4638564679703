<template>
    <div class="StationDetail full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <navigation-left-small :no-back-button="stationsCount === 1"/>
            <div class="content-area flex-grow-1 align-stretch bg-white d-flex flex-column">


<!--                Modal pretest done   -->
                <div v-if="stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.status.status === 6"
                     class="PreTestInfo fullModal d-flex align-center justify-center ">
                    <div class="message">
                        <p>{{ $t('overview.pretestDone') }} </p>

                        <v-btn-toggle v-model="tubesAfterPretest" multiple class="tube-buttons mt-5" color="disiRed">
                            <div class="row d-flex justify-center">
                                <v-btn :value="1" elevation="0" class="content-btn small ma-3" :disabled="stations[activeStationId - 1].runningTest.tubeList[0].disintegration === 100">
                                    {{ $t('general.tube') }} 1
                                </v-btn>
                                <v-btn :value="2" elevation="0" class="content-btn small ma-3" :disabled="stations[activeStationId - 1].runningTest.tubeList[1].disintegration === 100">
                                    {{ $t('general.tube') }} 2
                                </v-btn>
                                <v-btn :value="3" elevation="0" class="content-btn small ma-3" :disabled="stations[activeStationId - 1].runningTest.tubeList[2].disintegration === 100">
                                    {{ $t('general.tube') }} 3
                                </v-btn>
                                <v-btn :value="4" elevation="0" class="content-btn small ma-3" :disabled="stations[activeStationId - 1].runningTest.tubeList[3].disintegration === 100">
                                    {{ $t('general.tube') }} 4
                                </v-btn>
                                <v-btn :value="5" elevation="0" class="content-btn small ma-3" :disabled="stations[activeStationId - 1].runningTest.tubeList[4].disintegration === 100">
                                    {{ $t('general.tube') }} 5
                                </v-btn>
                                <v-btn :value="6" elevation="0" class="content-btn small ma-3" :disabled="stations[activeStationId - 1].runningTest.tubeList[5].disintegration === 100">
                                    {{ $t('general.tube') }} 6
                                </v-btn>
                            </div>
                        </v-btn-toggle>

                        <div class="d-flex justify-center mt-3">
                            <v-btn elevation="0"  @click="cancelTest" class="content-btn small ma-5">
                                <div class="cancel" ></div>
                                {{ $t('footerButton.cancel') }}
                            </v-btn>
                            <v-btn elevation="0" @click="startAfterPretest" class="content-btn small ma-5">
                                <div class="start" ></div>
                                {{ $t('footerButton.start') }}
                            </v-btn>
                        </div>
                    </div>
                </div>

<!--                Modal 1 part of combiTest done      -->
                <div v-if="stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.status.status === 5"
                    class="CombiTestInfo fullModal d-flex align-center justify-center ">
                    <div class="message">
                        <p>{{ $t('overview.startCombiTest') }} </p>
                        <div class="d-flex justify-center mt-3">
                            <v-btn elevation="0" class="content-btn small ma-6" @click="cancelTest">
                                <div class="cancel"></div>
                                {{ $t('footerButton.cancel') }}
                            </v-btn>
                            <v-btn elevation="0" class="content-btn small ma-6" @click="startCombiTest">
                                <div class="start"></div>
                                {{ $t('footerButton.start') }}
                            </v-btn>
                        </div>
                    </div>
                </div>

<!--                Modal test without disc is done and not all tubes have been stopped manually     -->
                <div v-if="stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.status.status === 14"
                     class="WithoutDiscTestInfo fullModal d-flex align-center justify-center ">
                    <div class="message">
                        <p>{{ $t('overview.finishWithoutDisc') }} </p>

                        <v-btn-toggle v-model="disintegratedTubes" multiple class="tube-buttons mt-5" color="disiRed">
                            <div class="row d-flex justify-center">
                                <v-btn :value="1" elevation="0" class="content-btn small ma-3" :disabled="stations[activeStationId - 1].runningTest.tubeList[0].disintegration === 100">
                                    {{ $t('general.tube') }} 1
                                </v-btn>
                                <v-btn :value="2" elevation="0" class="content-btn small ma-3" :disabled="stations[activeStationId - 1].runningTest.tubeList[1].disintegration === 100">
                                    {{ $t('general.tube') }} 2
                                </v-btn>
                                <v-btn :value="3" elevation="0" class="content-btn small ma-3" :disabled="stations[activeStationId - 1].runningTest.tubeList[2].disintegration === 100">
                                    {{ $t('general.tube') }} 3
                                </v-btn>
                                <v-btn :value="4" elevation="0" class="content-btn small ma-3" :disabled="stations[activeStationId - 1].runningTest.tubeList[3].disintegration === 100">
                                    {{ $t('general.tube') }} 4
                                </v-btn>
                                <v-btn :value="5" elevation="0" class="content-btn small ma-3" :disabled="stations[activeStationId - 1].runningTest.tubeList[4].disintegration === 100">
                                    {{ $t('general.tube') }} 5
                                </v-btn>
                                <v-btn :value="6" elevation="0" class="content-btn small ma-3" :disabled="stations[activeStationId - 1].runningTest.tubeList[5].disintegration === 100">
                                    {{ $t('general.tube') }} 6
                                </v-btn>
                            </div>
                        </v-btn-toggle>

                        <div class="d-flex justify-center mt-3">
                            <v-btn elevation="0" class="content-btn small ma-5" @click="cancelTest">
                                <div class="cancel"></div>
                                {{ $t('footerButton.cancel') }}
                            </v-btn>
                            <v-btn elevation="0" class="content-btn small ma-5" @click="finishTestWithoutDisc" >
                                <div class="ok"></div>
                                {{ $t('footerButton.ok') }}
                            </v-btn>
                        </div>
                    </div>
                </div>

<!--                Title-Bar   -->
                <div class="title-bar d-flex pa-4 pl-8 pr-8 align-center">
                    <p class="stationNumber mr-4 font-weight-regular">{{ activeStationId }}</p>
                    <p class="font-weight-regular"
                       v-if="stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.status.status > 1 && stations[activeStationId - 1].runningTest.productName !== '-'">
                        {{ stations[activeStationId - 1].runningTest.productName }}
                        <span v-if="stations[activeStationId - 1].runningTest.productNumber !== '-'">
                            ({{ stations[activeStationId - 1].runningTest.productNumber }})
                        </span>
                    </p>
                    <v-spacer/>
                    <p class="font-weight-regular"
                       v-if="stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.status.status > 1">
                        {{ $t('stationDetail.batchNo') }}: {{ stations[activeStationId - 1].runningTest.batch }}
                    </p>
                    <p v-if="stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.status.status > 1"
                       class="plannedTime ml-8 d-flex align-center font-weight-regular">
                        {{ timeFormat(stations[activeStationId - 1].runningTest.plannedTime) }}
                    </p>
                </div>

                <v-row class="pa-4">

<!--                Circles   -->
                    <v-col cols="4" class="firstCol d-flex flex-column pa-4 pb-0">
                        <div class="detail_basket flex-grow-1">
                            <div class="tubes">
                                <div class="detail_basketContainer--top d-flex justify-space-between align-end">
                                    <div class="tube_info"
                                         v-if="stations[activeStationId - 1].runningTest.tubeList[5]"
                                         :class="{'finished': stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.tubeList[5].disintegration === 100}">
                                        6
                                    </div>
                                    <div class="tube_info disconnected" v-else>
                                        6
                                    </div>
                                    <div class="tube_info align-self-start"
                                         v-if="stations[activeStationId - 1].runningTest.tubeList[0]"
                                         :class="{'finished': stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.tubeList[0].disintegration === 100}">
                                        1
                                    </div>
                                    <div class="tube_info align-self-start disconnected" v-else>
                                        1
                                    </div>
                                    <div class="tube_info"
                                         v-if="stations[activeStationId - 1].runningTest.tubeList[1]"
                                         :class="{'finished': stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.tubeList[1].disintegration === 100}">
                                        2
                                    </div>
                                    <div class="tube_info disconnected" v-else>
                                        2
                                    </div>
                                </div>
                                <div class="detail_basketContainer--bottom d-flex justify-space-between mt-4">
                                    <div class="tube_info"
                                         v-if="stations[activeStationId - 1].runningTest.tubeList[4]"
                                         :class="{'finished': stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.tubeList[4].disintegration === 100}">
                                        5
                                    </div>
                                    <div class="tube_info disconnected" v-else>
                                        5
                                    </div>
                                    <div class="tube_info align-self-end"
                                         v-if="stations[activeStationId - 1].runningTest.tubeList[3]"
                                         :class="{'finished': stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.tubeList[3].disintegration === 100}">
                                        4
                                    </div>
                                    <div class="tube_info align-self-end disconnected" v-else>
                                        4
                                    </div>
                                    <div class="tube_info"
                                         v-if="stations[activeStationId - 1].runningTest.tubeList[2]"
                                         :class="{'finished': stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.tubeList[2].disintegration === 100}">
                                        3
                                    </div>
                                    <div class="tube_info disconnected" v-else>
                                        3
                                    </div>
                                </div>
                                <div
                                    v-if="stations[activeStationId - 1].connected !== 'disconnected'"
                                    class="detail_basketContainer--top d-flex justify-space-between align-end">

                                    <v-progress-circular :size="47" :width="4" :rotate="-90"
                                                         v-if="stations[activeStationId - 1].runningTest.tubeList[5]"
                                                         :value="stations[activeStationId - 1].runningTest.tubeList[5].disintegration"
                                                         color="disiIrisblue"
                                                         :class="{'rotate': stations[activeStationId - 1].runningTest.tubeList[5].disintegration !== 100 && testIsRunning && (stations[activeStationId - 1].runningTest.manual || stations[activeStationId - 1].runningTest.withoutDisc)}"></v-progress-circular>
                                    <v-progress-circular :size="47" :width="4" :rotate="-90"
                                                         v-else
                                                         :value="0"
                                                         color="disiBluegreyMedium"
                                                         class=""></v-progress-circular>

                                    <v-progress-circular :size="47" :width="4" :rotate="-90"
                                                         v-if="stations[activeStationId - 1].runningTest.tubeList[0]"
                                                         :value="stations[activeStationId - 1].runningTest.tubeList[0].disintegration"
                                                         color="disiIrisblue"
                                                         :class="{'rotate': stations[activeStationId - 1].runningTest.tubeList[0].disintegration !== 100 && testIsRunning && (stations[activeStationId - 1].runningTest.manual || stations[activeStationId - 1].runningTest.withoutDisc)}"
                                                         class="align-self-start"></v-progress-circular>
                                    <v-progress-circular :size="47" :width="4" :rotate="-90"
                                                         v-else
                                                         :value="0"
                                                         color="disiBluegreyMedium"
                                                         class="align-self-start"></v-progress-circular>

                                    <v-progress-circular :size="47" :width="4" :rotate="-90"
                                                         v-if="stations[activeStationId - 1].runningTest.tubeList[1]"
                                                         :value="stations[activeStationId - 1].runningTest.tubeList[1].disintegration"
                                                         color="disiIrisblue"
                                                         :class="{'rotate': stations[activeStationId - 1].runningTest.tubeList[1].disintegration !== 100 && testIsRunning && (stations[activeStationId - 1].runningTest.manual || stations[activeStationId - 1].runningTest.withoutDisc)}"></v-progress-circular>
                                    <v-progress-circular :size="47" :width="4" :rotate="-90"
                                                         v-else
                                                         :value="0"
                                                         color="disiBluegreyMedium"
                                                         class=""></v-progress-circular>
                                </div>
                                <div v-else class="detail_basketContainer--top d-flex justify-space-between align-end">
                                    <v-progress-circular :size="47" :width="4" :rotate="0" :value="0"
                                                         color="disiIrisblue" class=""></v-progress-circular>
                                    <v-progress-circular :size="47" :width="4" :rotate="0" :value="0"
                                                         color="disiIrisblue"
                                                         class="align-self-start"></v-progress-circular>
                                    <v-progress-circular :size="47" :width="4" :rotate="0" :value="0"
                                                         color="disiIrisblue" class=""></v-progress-circular>
                                </div>
                                <div
                                    v-if="stations[activeStationId - 1].connected !== 'disconnected'"
                                    class="detail_basketContainer--bottom d-flex justify-space-between mt-4">
                                    <v-progress-circular :size="47" :width="4" :rotate="-90"
                                                         v-if="stations[activeStationId - 1].runningTest.tubeList[4]"
                                                         :value="stations[activeStationId - 1].runningTest.tubeList[4].disintegration"
                                                          color="disiIrisblue"
                                                         :class="{'rotate': stations[activeStationId - 1].runningTest.tubeList[4].disintegration !== 100 && testIsRunning && (stations[activeStationId - 1].runningTest.manual || stations[activeStationId - 1].runningTest.withoutDisc)}"></v-progress-circular>
                                    <v-progress-circular :size="47" :width="4" :rotate="-90"
                                                         v-else
                                                         :value="0"
                                                         color="disiBluegreyMedium"
                                                         class=""></v-progress-circular>

                                    <v-progress-circular :size="47" :width="4" :rotate="-90"
                                                         v-if="stations[activeStationId - 1].runningTest.tubeList[3]"
                                                         :value="stations[activeStationId - 1].runningTest.tubeList[3].disintegration"
                                                         color="disiIrisblue"
                                                         :class="{'rotate': stations[activeStationId - 1].runningTest.tubeList[3].disintegration !== 100 && testIsRunning && (stations[activeStationId - 1].runningTest.manual || stations[activeStationId - 1].runningTest.withoutDisc)}"
                                                         class="align-self-end"></v-progress-circular>
                                    <v-progress-circular :size="47" :width="4" :rotate="-90"
                                                         v-else
                                                         :value="0"
                                                         color="disiBluegreyMedium"
                                                         class="align-self-end"></v-progress-circular>

                                    <v-progress-circular :size="47" :width="4" :rotate="-90"
                                                         v-if="stations[activeStationId - 1].runningTest.tubeList[2]"
                                                         :value="stations[activeStationId - 1].runningTest.tubeList[2].disintegration"
                                                         color="disiIrisblue"
                                                         :class="{'rotate': stations[activeStationId - 1].runningTest.tubeList[2].disintegration !== 100 && testIsRunning && (stations[activeStationId - 1].runningTest.manual || stations[activeStationId - 1].runningTest.withoutDisc)}"></v-progress-circular>
                                    <v-progress-circular :size="47" :width="4" :rotate="-90"
                                                         v-else
                                                         :value="0"
                                                         color="disiBluegreyMedium"
                                                         class=""></v-progress-circular>
                                </div>
                                <div v-else class="detail_basketContainer--bottom d-flex justify-space-between mt-4">
                                    <v-progress-circular :size="47" :width="4" :rotate="0" :value="0"
                                                         color="disiIrisblue" class=""></v-progress-circular>
                                    <v-progress-circular :size="47" :width="4" :rotate="0" :value="0"
                                                         color="disiIrisblue"
                                                         class="align-self-end"></v-progress-circular>
                                    <v-progress-circular :size="47" :width="4" :rotate="0" :value="0"
                                                         color="disiIrisblue" class=""></v-progress-circular>
                                </div>
                            </div>
                            <v-progress-circular :size="256" :width="14" :rotate="-90" :value="runningTest"
                                                 color="disiIrisblue" class=" overAll"
                                                 :class="{'rotate': testIsRunning}">
                            </v-progress-circular>
                        </div>
                        <p v-if="stations[activeStationId - 1].connected !== 'disconnected'"
                           class="detail_testStatus d-flex align-center"
                           :class="{'warn': stations[activeStationId - 1].runningTest.status.status > 5 && stations[activeStationId - 1].runningTest.status.status != 7, 'failed':  stations[activeStationId - 1].runningTest.status.status > 99}">
                            {{ stations[activeStationId - 1].runningTest.status.message }}
                        </p>
                        <p v-else class="detail_testStatus d-flex align-center failed">
                            {{ $t('overview.stationDisconnected') }}
                        </p>
                    </v-col>

<!--                Tube-Info   -->
                    <v-col cols="4" class="d-flex flex-column pa-0 pl-4 pr-4">
                        <v-row class="testingTime pa-0 ml-auto d-flex flex-grow-0 align-center">
                        </v-row>
                        <v-row class="tube_testingTime pa-0 d-flex flex-grow-0 align-center"
                               :class="{'disconnected': !stations[activeStationId - 1].runningTest.tubeList[0]}">
                            <p>{{ $t('general.tube') }} 1</p>
                            <v-spacer/>
                            <p v-if="stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.status.status > 1 && stations[activeStationId - 1].runningTest.tubeList[0]"
                               class="readOnly pos-relative">
                                {{ timeFormat(stations[activeStationId - 1].runningTest.tubeList[0].time) }}
                                <button
                                    v-if="(stations[activeStationId - 1].runningTest.manual || stations[activeStationId - 1].runningTest.withoutDisc) && stations[activeStationId - 1].runningTest.status.status === 3 && stations[activeStationId - 1].runningTest.tubeList[0].disintegration !== 100"
                                    class="content-btn stop" @click="stopTube(1)"></button>
                            </p>
                            <p v-else class="readOnly"></p>
                        </v-row>
                        <v-row class="tube_testingTime pa-0 mt-3 d-flex flex-grow-0 align-center"
                               :class="{'disconnected': !stations[activeStationId - 1].runningTest.tubeList[2]}">
                            <p>{{ $t('general.tube') }} 3</p>
                            <v-spacer/>
                            <p v-if="stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.status.status > 1 && stations[activeStationId - 1].runningTest.tubeList[2]"
                               class="readOnly pos-relative">
                                {{ timeFormat(stations[activeStationId - 1].runningTest.tubeList[2].time) }}
                                <button
                                    v-if="(stations[activeStationId - 1].runningTest.manual || stations[activeStationId - 1].runningTest.withoutDisc) && stations[activeStationId - 1].runningTest.status.status === 3 && stations[activeStationId - 1].runningTest.tubeList[2].disintegration !== 100"
                                    class="content-btn stop" @click="stopTube(3)"></button>
                            </p>
                            <p v-else class="readOnly"></p>
                        </v-row>
                        <v-row class="tube_testingTime pa-0 mt-3 d-flex flex-grow-0 align-center"
                               :class="{'disconnected': !stations[activeStationId - 1].runningTest.tubeList[4]}">
                            <p>{{ $t('general.tube') }} 5</p>
                            <v-spacer/>
                            <p v-if="stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.status.status > 1 && stations[activeStationId - 1].runningTest.tubeList[4]"
                               class="readOnly pos-relative">
                                {{ timeFormat(stations[activeStationId - 1].runningTest.tubeList[4].time) }}
                                <button
                                    v-if="(stations[activeStationId - 1].runningTest.manual || stations[activeStationId - 1].runningTest.withoutDisc) && stations[activeStationId - 1].runningTest.status.status === 3 && stations[activeStationId - 1].runningTest.tubeList[4].disintegration !== 100"
                                    class="content-btn stop" @click="stopTube(5)"></button>
                            </p>
                            <p v-else class="readOnly"></p>
                        </v-row>
                    </v-col>
                    <v-col cols="4" class="d-flex flex-column pa-0 pl-4 pr-4">
                        <v-row class="testingTime pa-0 ml-auto d-flex flex-grow-0 align-center">
<!--                            <v-progress-circular-->
<!--                                v-if="stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.status.status > 1"-->
<!--                                :size="35" :width="4" :rotate="-90" :value="testtimePercent" color="disiIrisblue"-->
<!--                                class="mr-4 mb-1"></v-progress-circular>-->
                            <p v-if="stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.status.status > 1"
                               class="detail_testingTime p-big-green" :class="{overtime : hasOvertime}">
                                {{ timeFormat(stations[activeStationId - 1].runningTest.testTime) }}
                            </p>
                        </v-row>
                        <v-row class="tube_testingTime pa-0 d-flex flex-grow-0 align-center"
                               :class="{'disconnected': !stations[activeStationId - 1].runningTest.tubeList[1]}">
                            <p>{{ $t('general.tube') }} 2</p>
                            <v-spacer/>
                            <p v-if="stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.status.status > 1 && stations[activeStationId - 1].runningTest.tubeList[1]"
                               class="readOnly pos-relative">
                                {{ timeFormat(stations[activeStationId - 1].runningTest.tubeList[1].time) }}
                                <button
                                    v-if="(stations[activeStationId - 1].runningTest.manual || stations[activeStationId - 1].runningTest.withoutDisc) && stations[activeStationId - 1].runningTest.status.status === 3 && stations[activeStationId - 1].runningTest.tubeList[1].disintegration !== 100"
                                    class="content-btn stop" @click="stopTube(2)"></button>
                            </p>
                            <p v-else class="readOnly"></p>
                        </v-row>
                        <v-row class="tube_testingTime mt-3 pa-0 d-flex flex-grow-0 align-center"
                               :class="{'disconnected': !stations[activeStationId - 1].runningTest.tubeList[3]}">
                            <p>{{ $t('general.tube') }} 4</p>
                            <v-spacer/>
                            <p v-if="stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.status.status > 1 && stations[activeStationId - 1].runningTest.tubeList[3]"
                               class="readOnly pos-relative">
                                {{ timeFormat(stations[activeStationId - 1].runningTest.tubeList[3].time) }}
                                <button
                                    v-if="(stations[activeStationId - 1].runningTest.manual || stations[activeStationId - 1].runningTest.withoutDisc) && stations[activeStationId - 1].runningTest.status.status === 3 && stations[activeStationId - 1].runningTest.tubeList[3].disintegration !== 100"
                                    class="content-btn stop" @click="stopTube(4)"></button>
                            </p>
                            <p v-else class="readOnly"></p>
                        </v-row>
                        <v-row class="tube_testingTime mt-3 pa-0 d-flex flex-grow-0 align-center"
                               :class="{'disconnected': !stations[activeStationId - 1].runningTest.tubeList[5]}">
                            <p>{{ $t('general.tube') }} 6</p>
                            <v-spacer/>
                            <p v-if="stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.status.status > 1 && stations[activeStationId - 1].runningTest.tubeList[5]"
                               class="readOnly pos-relative">
                                {{ timeFormat(stations[activeStationId - 1].runningTest.tubeList[5].time) }}
                                <button
                                    v-if="(stations[activeStationId - 1].runningTest.manual || stations[activeStationId - 1].runningTest.withoutDisc) && stations[activeStationId - 1].runningTest.status.status === 3 && stations[activeStationId - 1].runningTest.tubeList[5].disintegration !== 100"
                                    class="content-btn stop" @click="stopTube(6)"></button>
                            </p>
                            <p v-else class="readOnly"></p>
                        </v-row>

<!--                        Heater   -->
                        <v-row class="pa-0 flex-grow-1 d-flex justify-end align-end">
                            <p v-if="isHeaterOn && stations[activeStationId - 1].heater.temp <= 0"
                               class="temperature p-big-green mr-6 d-flex align-center">00°</p>
                            <p v-if="isHeaterOn && stations[activeStationId - 1].heater.temp > 0"
                               class="temperature p-big-green mr-6 d-flex align-center">
                                {{ stations[activeStationId - 1].heater.temp }}°</p>
                            <p v-if="!isHeaterOn" class="temperature p-big-green mr-6 d-flex align-center disabled">
                                00°</p>
                            <div class="heater-switch" @mouseup="heaterOnOff">
                                <p class="switchLabel text-center">{{ $t('stationDetail.heater') }}:</p>
                                <switch-on-off v-if="activeStationId === 1" name="stations-0-heater-status"
                                               :disabled="stations[activeStationId - 1].connected === 'disconnected' || stations[activeStationId - 1].runningTest.status.status === 3 || stations[activeStationId - 1].runningTest.status.status === 5 || stations[activeStationId - 1].runningTest.status.status === 7 || stations[activeStationId - 1].runningTest.status.status === 9 || stations[activeStationId - 1].runningTest.status.status === 10 || stations[activeStationId - 1].runningTest.status.status === 13 || !checkUserRights('heater')"
                                               :class="{'active': stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].heater.status}"
                                               class="ml-auto"/>
                                <switch-on-off v-if="activeStationId === 2" name="stations-1-heater-status"
                                               :disabled="stations[activeStationId - 1].connected === 'disconnected' || stations[activeStationId - 1].runningTest.status.status === 3 || stations[activeStationId - 1].runningTest.status.status === 5 || stations[activeStationId - 1].runningTest.status.status === 7 || stations[activeStationId - 1].runningTest.status.status === 9 || stations[activeStationId - 1].runningTest.status.status === 10 || stations[activeStationId - 1].runningTest.status.status === 13 || !checkUserRights('heater')"
                                               :class="{'active': stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].heater.status}"
                                               class="ml-auto"/>
                                <switch-on-off v-if="activeStationId === 3" name="stations-2-heater-status"
                                               :disabled="stations[activeStationId - 1].connected === 'disconnected' || stations[activeStationId - 1].runningTest.status.status === 3 || stations[activeStationId - 1].runningTest.status.status === 5 || stations[activeStationId - 1].runningTest.status.status === 7 || stations[activeStationId - 1].runningTest.status.status === 9 || stations[activeStationId - 1].runningTest.status.status === 10 || stations[activeStationId - 1].runningTest.status.status === 13 || !checkUserRights('heater')"
                                               :class="{'active': stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].heater.status}"
                                               class="ml-auto"/>
                                <switch-on-off v-if="activeStationId === 4" name="stations-3-heater-status"
                                               :disabled="stations[activeStationId - 1].connected === 'disconnected' || stations[activeStationId - 1].runningTest.status.status === 3 || stations[activeStationId - 1].runningTest.status.status === 5 || stations[activeStationId - 1].runningTest.status.status === 7 || stations[activeStationId - 1].runningTest.status.status === 9 || stations[activeStationId - 1].runningTest.status.status === 10 || stations[activeStationId - 1].runningTest.status.status === 13 || !checkUserRights('heater')"
                                               :class="{'active': stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].heater.status}"
                                               class="ml-auto"/>
                            </div>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button button-function="reportWaiting"
                           v-if="testIsRunning">
            </footer-button>
            <footer-button buttonFunction="report" @footerButtonClick="showReport"
                           v-else-if="stations[activeStationId - 1].connected !== 'disconnected' && (stations[activeStationId - 1].runningTest.status.status === 4 || stations[activeStationId - 1].runningTest.status.status > 99)">
            </footer-button>
            <footer-button v-else></footer-button>
            <footer-button buttonFunction="quickTest" @footerButtonClick="goToQuickTest"
                           v-if="stations[activeStationId - 1].connected !== 'disconnected' && (stations[activeStationId - 1].runningTest.status.status === 1 || stations[activeStationId - 1].runningTest.status.status === 2 || stations[activeStationId - 1].runningTest.status.status === 4 || stations[activeStationId - 1].runningTest.status.status > 99)"
                           :class="{'disabled': !checkUserRights('quickTest')}"></footer-button>
            <footer-button buttonFunction="pause" @footerButtonClick="pauseTest"
                           v-else-if="testIsRunning && stations[activeStationId - 1].runningTest.testType === 'QuickTest'"
                           :class="{'disabled': (!checkUserRights('quickTestPause'))}"></footer-button>
            <footer-button buttonFunction="pause" @footerButtonClick="pauseTest"
                           v-else-if="testIsRunning && stations[activeStationId - 1].runningTest.testType === 'ProductTest'"
                           :class="{'disabled': (!checkUserRights('productTestPause'))}"></footer-button>
            <footer-button buttonFunction="resume" @footerButtonClick="resumeTest"
                           v-else-if="stations[activeStationId - 1].connected !== 'disconnected' && stations[activeStationId - 1].runningTest.status.status === 13"
                           :class="{'disabled': (!checkUserRights('quickTestPause') && !checkUserRights('productTestPause'))}"></footer-button>
            <footer-button v-else></footer-button>
            <footer-button buttonFunction="productTest" @footerButtonClick="goToProductTest"
                           v-if="stations[activeStationId - 1].connected !== 'disconnected' && (stations[activeStationId - 1].runningTest.status.status === 1 || stations[activeStationId - 1].runningTest.status.status === 2 || stations[activeStationId - 1].runningTest.status.status === 4 || stations[activeStationId - 1].runningTest.status.status > 99)"
                           :class="{'disabled': !checkUserRights('productTest')}"></footer-button>
            <footer-button buttonFunction="cancel" @footerButtonClick="cancelTest"
                           v-else-if="(testIsRunning || stations[activeStationId - 1].runningTest.status.status === 13 || stations[activeStationId - 1].runningTest.status.status === 14) && stations[activeStationId - 1].runningTest.testType === 'QuickTest'"
                           :class="{'disabled': (!checkUserRights('quickTestCancel'))}"></footer-button>
            <footer-button buttonFunction="cancel" @footerButtonClick="cancelTest"
                           v-else-if="(testIsRunning || stations[activeStationId - 1].runningTest.status.status === 13 || stations[activeStationId - 1].runningTest.status.status === 14) && stations[activeStationId - 1].runningTest.testType === 'ProductTest'"
                           :class="{'disabled': (!checkUserRights('productTestCancel'))}"></footer-button>
            <footer-button v-else></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
// status codes runningTest.status.status
//gruen ready after restart  >> 1
//gruen ready  >> 2  -> wie finished
//gruen running  >> 3
//gruen finished  >> 4 -> wie ready
//gruen first part of combination test finished  >> 5
//orange pretest complete >> 6
//gruen pretest running >> 7
//orange auto heating after test start  >> 9
//orange auto cooling after test start  >> 10
//orange manual heating  >> 11
//orange manual cooling  >> 12
//orange paused  >> 13
//orange waiting for user input >> 14
//rot failed  >> 100
//rot Test aborted by system >> 101
//rot connectionLost  >> 102
//rot noBeaker  >> 103
//grau not connected >> 0
import NavigationLeftSmall from '@/components/NavigationLeftSmall.vue'
import SwitchOnOff from '@/components/SwitchOnOff.vue'
import FooterButton from '@/components/FooterButton.vue'
import {mapGetters, mapState} from 'vuex'
import mixins from '../mixins/mixins';

export default {
    name: 'StationTestDetail',
    components: {
        NavigationLeftSmall,
        SwitchOnOff,
        FooterButton,
    },
    props: {},
    data() {
        return {
            time: {
                tube1: null,
                tube2: null,
                tube3: null,
                tube4: null,
                tube5: null,
                tube6: 552,
                overall: null
            },
            disintegratedTubes: [],
            tubesAfterPretest: [],
        }
    },
    computed: {
        ...mapState([
            'activeStationId',
            'stations',
            'reportlist',
            'reportdetails',
            'methodlist',
            'errorScreen'
        ]),
        ...mapGetters([
            'userRights',
        ]),
        isHeaterOn() {
            if (this.stations[this.activeStationId - 1].connected === 'disconnected') {
                return false;
            } else {
                return this.stations[this.activeStationId - 1].heater.status;
            }
        },
        runningTest() {
            if (this.stations[this.activeStationId - 1].connected === 'disconnected') {
                return 0;
            } else {
                if (this.stations[this.activeStationId - 1].runningTest.status.status === 2 ||
                    this.stations[this.activeStationId - 1].runningTest.status.status === 4 ||
                    this.stations[this.activeStationId - 1].runningTest.status.status > 99) {
                    return 100;
                } else if (this.testIsRunning || this.stations[this.activeStationId - 1].runningTest.status.status === 5 ||
                    this.stations[this.activeStationId - 1].runningTest.status.status === 6 ||
                    this.stations[this.activeStationId - 1].runningTest.status.status === 13) {
                    return 50;
                } else {
                    return 0;
                }
            }
        },
        testtimePercent() {
            return 0;
            // return 100 / this.stations[this.activeStationId - 1].runningTest.plannedTime * this.stations[this.activeStationId - 1].runningTest.testTime;
        },
        stationsCount() {
            let activeStations = [];
            this.stations.forEach(station => {
                if (station.connected !== 'noStation' && station.connected !== '') {
                    activeStations.push(station);
                }
            })
            return activeStations.length
        },
        hasOvertime() {
            if (this.stations[this.activeStationId - 1].runningTest.methodId !== 0) {
                let methodId = this.stations[this.activeStationId - 1].runningTest.methodId;
                let method = this.methodlist.find(item => item.id === methodId);
                if (method && method.maxTestingTimeStatus) {
                    if (this.stations[this.activeStationId - 1].runningTest.testTime > method.testingTime) {
                        return true;
                    }
                }
            }
            return false;
        },
        testIsRunning(){
            if (this.stations[this.activeStationId - 1].connected !== 'disconnected' &&
                (this.stations[this.activeStationId - 1].runningTest.status.status === 3 || //running
                this.stations[this.activeStationId - 1].runningTest.status.status === 7 || //pretest is running
                this.stations[this.activeStationId - 1].runningTest.status.status === 9 || //auto heating after test start
                this.stations[this.activeStationId - 1].runningTest.status.status === 10 || //auto cooling after test start
                this.stations[this.activeStationId - 1].runningTest.status.status === 11 || //manual heating
                this.stations[this.activeStationId - 1].runningTest.status.status === 12)) //manual cooling
            {
                return true;
            } else return false;
        }
    },
    methods: {
        goToQuickTest() {
            this.$router.push('quick-test');
        },
        goToProductTest() {
            this.$router.push('product-test');
        },
        stopTube(tubeNr) {
            let data = {
                'id' :this.activeStationId,
                'testId': this.stations[this.activeStationId - 1].runningTest.testId,
                'tube': tubeNr
            };
            this.$store.dispatch('postAxiosNoSetter', ['/disi/testing/tubeDisintegrated', data]).then(
                response =>{
                    if (response.status === 200) {
                        //toDo: this commit sets value of tube to 100, probably not necessary when status correct
                        this.$store.commit('stopTube', tubeNr);
                    }
                }
            )
        },
        async showReport() {
            let data = {
                id: this.stations[this.activeStationId - 1].runningTest.testId
            }
            await this.$store.dispatch('postAxiosSetter', ['/disi/reports/details', data]).then(
                response => {
                    if (response.status === 200) {
                        this.$store.commit('changeReport', response.data);
                    } else {
                        this.$store.commit('changeReport', {});
                    }
                }
            )
        },
        pauseTest() {
            this.$store.dispatch('postAxiosNoSetter', ['/disi/testing/pause', {
                'id': this.activeStationId,
                'testId': this.stations[this.activeStationId - 1].runningTest.testId
            }])
                .then(response => {
                    if (response.status === 200) {
                        this.$store.dispatch('getAxiosSetter', ['/disi/station/status', 'stations']);
                    }
                })
        },
        resumeTest() {
            this.$store.dispatch('postAxiosNoSetter', ['/disi/testing/restart', {
                'id': this.activeStationId,
                'testId': this.stations[this.activeStationId - 1].runningTest.testId
            }])
                .then(response => {
                    if (response.status === 200) {
                        this.$store.dispatch('getAxiosSetter', ['/disi/station/status', 'stations']);
                    }
                })
        },
        cancelTest() {
            this.$store.dispatch('postAxiosNoSetter', ['/disi/testing/cancel', {
                'id': this.activeStationId,
                'testId': this.stations[this.activeStationId - 1].runningTest.testId
            }])
                .then(response => {
                    if (response.status === 200) {
                        this.$store.dispatch('getAxiosSetter', ['/disi/station/status', 'stations']);
                    }
                })
        },
        startCombiTest() {
            this.$store.dispatch('postAxiosNoSetter', ['/disi/testing/startCombinationTestPartTwo', {
                'id': this.activeStationId,
                'testId': this.stations[this.activeStationId - 1].runningTest.testId
            }])
                .then(response => {
                    if (response.status === 200) {
                        this.$store.dispatch('getAxiosSetter', ['/disi/station/status', 'stations']);
                    }
                })
        },
        heaterOnOff() {
            if (this.stations[this.activeStationId - 1].connected !== 'disconnected' && this.stations[this.activeStationId - 1].runningTest.status.status !== 3 && this.stations[this.activeStationId - 1].runningTest.status.status !== 5 && this.stations[this.activeStationId - 1].runningTest.status.status !== 7 && this.stations[this.activeStationId - 1].runningTest.status.status !== 9 && this.stations[this.activeStationId - 1].runningTest.status.status !== 10 && this.stations[this.activeStationId - 1].runningTest.status.status !== 13 && this.checkUserRights('heater')) {
                this.$store.dispatch('postAxiosNoSetter', ['/disi/testing/heater', {
                    'id': this.activeStationId,
                    'heater': !this.isHeaterOn
                }]);
            }
        },
        getStationStatus() {
            this.$store.dispatch('getAxiosSetter', ['/disi/station/status', 'stations'])
                .then(response => {
                    if (response.status === 200) {
                        if (response.data[0].connected && this.$router.currentRoute.name === 'StationTestDetail') {
                            setTimeout(() => {
                                this.getStationStatus();
                            }, 500)
                        }
                    }
                })
        },
        finishTestWithoutDisc(){
            this.$store.dispatch("postAxiosNoSetter", ['/disi/testing/finish', {
                'id': this.activeStationId,
                'testId': this.stations[this.activeStationId - 1].runningTest.testId,
                'tubeDisintegrated': this.disintegratedTubes
            }])
                .then(response => {
                    if (response.status === 200) {
                        this.$store.dispatch('getAxiosSetter', ['/disi/station/status', 'stations']);
                    }
                })
        },
        startAfterPretest(){
            this.$store.dispatch("postAxiosNoSetter", ['/disi/testing/startAfterPretest', {
                'id': this.activeStationId,
                'testId': this.stations[this.activeStationId - 1].runningTest.testId,
                'tubeIntact': this.tubesAfterPretest,
            }])
                .then(response => {
                    if (response.status === 200) {
                        this.$store.dispatch('getAxiosSetter', ['/disi/station/status', 'stations']);
                    }
                })
        },
    },
    mixins: [mixins],
    beforeCreate() {
        // this.$store.dispatch('getAxiosSetter', ['/disi/media/list', 'medialist']);
        // this.$store.dispatch('getAxiosSetter', ['/disi/methods/list', 'methodlist']);
        // this.$store.dispatch('getAxiosSetter', ['/disi/testing/batch/list', 'batchlist']);
        // this.$store.dispatch('getAxiosSetter', ['/disi/products/list', 'productlist']);
        // this.$store.dispatch('getAxiosSetter', ['/disi/reports/list', 'reportlist']);
    },
    created() {
        this.getStationStatus();
        this.$watch('$store.state.errorScreen.status', function () {
            if (this.$store.state.errorScreen.status == false) {
                this.getStationStatus();
            }
        });
    },
}
</script>
<style scoped lang="scss">
.content {
    height: 100%;
    background-color: white;
}

.title-bar {
    background-color: $disiDarkblueMedium;
    font-weight: 500;
    height: 60px;

    p {
        color: white;
    }

    .stationNumber {
        font-size: 1.5rem;
    }

    .plannedTime {
        &::before {
            content: " ";
            display: inline-block;
            height: 25px;
            width: 25px;
            margin-right: 8px;
            margin-bottom: 3px;
            background-image: url('../assets/plannedTime_weiss.svg');
        }
    }
}

.firstCol {
    position: relative;
}

.detail_basket {
    position: relative;
}

.overAll {
    position: absolute;
    top: 0;
}

.tubes {
    position: relative;
    width: 256px;
    height: 256px;
}

.detail_basketContainer--top {
    position: absolute;
    top: 0;
    left: 0;
    height: 50%;
    width: 100%;
    padding: 30px 40px 10px 40px;
}

.detail_basketContainer--bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    padding: 10px 40px 30px 40px;
}

.v-progress-circular.finished {
    opacity: 0 !important;
}

.tube_info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    font-weight: 600;

    &.finished {
        background-color: $disiIrisblue;
        transition: all 0.2s ease-in 1s;
        color: white;
    }
}

.disconnected, .disconnected p {
    color: $disiBluegreyMedium;
}

.detail_testStatus {
    position: absolute;
    bottom: 0;
    width: 550px;
    height: 52px;

    &::before {
        content: "";
        display: inline-block;
        flex-shrink: 0;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        margin-right: 16px;
        margin-bottom: 2px;
        background-color: $disiIrisblue;
    }

    &::after {
        color: $disiGrey;
        content: attr(locale-running)
    }

    &.warn {
        &::before {
            background-color: $disiOrange;
        }
    }

    &.failed {
        &::before {
            background-color: $disiRed;
        }
    }
}

.readOnly {
    width: 175px;
}

.pos-relative {
    position: relative;
}

.testingTime {
    height: 52px;
}

.content-btn.stop {
    position: absolute;
    top: 4px;
    right: 4px;
    background-color: $disiRed;
    max-height: 42px;
    min-width: 42px;
    max-width: 42px;
    padding: 0;

    &:active, &:hover {
        background-color: $disiRed;
    }

    &::before {
        content: " ";
        height: 24px;
        width: 24px;
        background-image: url('../assets/stopwatch-solid_weiss.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }
}

.v-application p.p-big-green.temperature {
    &::after {
        content: " ";
        height: 52px;
        width: 35px;
        margin-top: -5px;
        margin-left: 8px;
        background-image: url('../assets/temperature_irisblue.svg');
    }

    &.disabled {
        color: $disiBluegreyMedium;

        &::after {
            background-image: url('../assets/temperature_bluegreyMedium.svg');
        }
    }
}

.v-application p.p-big-green.overtime {
    color: $disiRed;
}

.switchLabel {
    font-size: 17px;
    color: $disiGrey;
    padding-bottom: 8px;
}

.v-application .fullModal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: rgba($disiDarkblueLogo, 0.9);
    border: 10px solid $disiIrisblue;

    .message .successIcon {
        display: inline-block;
        margin-right: 20px;
        width: 40px;
        height: 25px;
        background-image: url("../assets/OK_irisblue.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .message p {
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.8rem;
        color: white;
        text-align: center;
        padding: 0 60px;

        &.tapScreen {
            color: white;
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

    .v-btn-toggle{
        background: none;
        width: 100%;
        flex-direction: column;

        .theme--light.v-btn{
            min-width: 100px!important;
            background-color: $disiDarkblueMedium !important;
            &::after{
                display: inline-block;
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                background-color: $disiBluegreyLight;
                opacity: 1;
                z-index: -1;
                margin-top: -2px;
            }
            &.v-btn--disabled.v-btn--has-bg {
                background-color: $disiLightIrisblue !important;
            }

            &.v-btn--active{
                background-color: $disiIrisblue !important;
            }

        }

    }

    .content-btn.small {
        padding-top: 2px;
        position: relative;
        font-weight: 500;
    }

    .cancel, .start, .ok {
        z-index: 100;
        &::before {
            display: inline-block;
            content: "";
            margin-right: 6px;
            width: 27px;
            height: 17px;
            background-size: contain;
            background-position: left;
        }
    }

    .cancel {
        &::before {
            background-image: url("../assets/cancel_weiss.svg");
            margin-bottom: -1px;
        }
    }

    .start {
        &::before {
            background-image: url("../assets/start_weiss.svg");
            height: 19px;
        }
    }

    .ok {
        &::before {
            background-image: url("../assets/OK_weiss.svg");
            height: 22px;
            width: 22px;
        }
    }

}

.detail_testingTime {
    font-variant-numeric: tabular-nums;
    min-width: 125px;
}
</style>